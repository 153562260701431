import React, { useState, useEffect } from 'react';
import { ChevronDown, Check, BarChart, Zap, TrendingUp, Users, Award, Workflow, X as CloseIcon } from 'lucide-react';
import { sendContactForm } from '../services/emailService';
import AIDataViz from '../components/AIDataViz';

const AIServicesLandingPage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission state
  const [submitStatus, setSubmitStatus] = useState({ success: null, message: '' }); // Track submission status
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    phone: '',
    message: '',
    inquiryType: 'get-started' // Default inquiry type
  });

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Reset form data
  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      company: '',
      phone: '',
      message: '',
      inquiryType: 'get-started'
    });
  };

  // Open modal with specific inquiry type
  const openModal = (type) => {
    setFormData(prev => ({...prev, inquiryType: type}));
    setShowModal(true);
    // Reset status when opening modal
    setSubmitStatus({ success: null, message: '' });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Send form data via EmailJS
    sendContactForm(formData)
      .then((response) => {
        console.log('Email sent successfully!', response);
        setSubmitStatus({
          success: true,
          message: 'Thanks for your interest! We will contact you soon.'
        });
        // Clear form but don't close modal immediately to show success message
        resetForm();
        // Close modal after delay to show success message
        setTimeout(() => {
          setShowModal(false);
          setSubmitStatus({ success: null, message: '' });
        }, 3000);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        setSubmitStatus({
          success: false,
          message: 'There was an error sending your message. Please try again or contact us directly.'
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white font-sans">
      {/* Navigation */}
      <nav className="flex justify-between items-center px-6 py-4 lg:px-12">
        <div className="flex items-center">
          <span className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">Mosyni AI</span>
        </div>
        <div className="hidden md:flex space-x-8">
          <a href="#services" className="hover:text-blue-400 transition">Services</a>
          <a href="#benefits" className="hover:text-blue-400 transition">Benefits</a>
          <a href="#methodology" className="hover:text-blue-400 transition">Methodology</a>
          <a href="#contact" className="hover:text-blue-400 transition">Contact</a>
        </div>
        <button
          onClick={() => openModal('get-started')}
          className="bg-blue-600 hover:bg-blue-700 px-6 py-2 rounded-full font-semibold transition"
        >
          Get Started
        </button>
      </nav>

      {/* Hero Section */}
      <header className={`flex flex-col items-center text-center pt-20 pb-16 px-4 transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
        <h1 className="text-4xl md:text-6xl font-bold mb-6 max-w-4xl bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500">
          AI-Powered Solutions for Small Business Success
        </h1>
        <p className="text-xl text-gray-300 max-w-2xl mb-10">
          Harness the power of artificial intelligence to automate tasks, gain valuable insights, and optimize your business processes without enterprise-level budgets.
        </p>
        <div className="flex flex-col sm:flex-row gap-4">
          <button
            onClick={() => openModal('demo')}
            className="bg-blue-600 hover:bg-blue-700 px-8 py-3 rounded-full font-semibold text-lg transition"
          >
            Get Started
          </button>
          <button
            onClick={() => {
              const servicesSection = document.getElementById('services');
              servicesSection?.scrollIntoView({ behavior: 'smooth' });
            }}
            className="bg-transparent border border-white hover:bg-white hover:text-gray-900 px-8 py-3 rounded-full font-semibold text-lg transition"
          >
            Explore Services
          </button>
        </div>
        <div className="mt-16 flex flex-wrap justify-center gap-8">
          <div className="flex items-center">
            <Check className="text-green-400 mr-2" size={20} />
            <span>No coding required</span>
          </div>
          <div className="flex items-center">
            <Check className="text-green-400 mr-2" size={20} />
            <span>Affordable plans</span>
          </div>
          <div className="flex items-center">
            <Check className="text-green-400 mr-2" size={20} />
            <span>Personalized solutions</span>
          </div>
        </div>
        {/* 3D Animation */}
        <div className="lg:w-1/2 flex justify-center">
            <div className="w-full max-w-lg">
              <AIDataViz />
            </div>
          </div>
      </header>

      {/* Services Section */}
      <section id="services" className="py-16 px-4 md:px-12 max-w-6xl mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-16 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
          Our AI Services
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <a href="/business-intelligence" className="block">
            <div className="bg-gray-800 bg-opacity-50 p-8 rounded-xl hover:transform hover:scale-105 transition duration-300">
              <div className="bg-blue-600 p-3 rounded-lg inline-block mb-4">
                <BarChart size={24} />
              </div>
              <h3 className="text-xl font-bold mb-3">Business Intelligence</h3>
              <p className="text-gray-300">
                Transform your data into actionable insights with AI-powered analytics that reveal hidden patterns and opportunities.
              </p>
            </div>
          </a>
          <a href="/process-automation" className="block">
            <div className="bg-gray-800 bg-opacity-50 p-8 rounded-xl hover:transform hover:scale-105 transition duration-300">
              <div className="bg-purple-600 p-3 rounded-lg inline-block mb-4">
                <Zap size={24} />
              </div>
              <h3 className="text-xl font-bold mb-3">Process Automation</h3>
              <p className="text-gray-300">
                Automate repetitive tasks and workflows to save time, reduce errors, and free your team to focus on growth.
              </p>
            </div>
          </a>
          <a href="/predictive-analytics" className="block">
            <div className="bg-gray-800 bg-opacity-50 p-8 rounded-xl hover:transform hover:scale-105 transition duration-300">
              <div className="bg-pink-600 p-3 rounded-lg inline-block mb-4">
                <TrendingUp size={24} />
              </div>
              <h3 className="text-xl font-bold mb-3">Predictive Analytics</h3>
              <p className="text-gray-300">
                Anticipate market trends and customer behavior with AI forecasting to make data-driven decisions.
              </p>
            </div>
          </a>
          <a href="/customer-insights" className="block">
            <div className="bg-gray-800 bg-opacity-50 p-8 rounded-xl hover:transform hover:scale-105 transition duration-300">
              <div className="bg-green-600 p-3 rounded-lg inline-block mb-4">
                <Users size={24} />
              </div>
              <h3 className="text-xl font-bold mb-3">Customer Insights</h3>
              <p className="text-gray-300">
                Understand customer needs and behaviors to build stronger relationships and drive loyalty with data-driven strategies.
              </p>
            </div>
          </a>
        </div>
      </section>

      {/* Benefits Section */}
      <section id="benefits" className="py-16 px-4 bg-gray-900">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold text-center mb-16 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
            Why Choose Our AI Solutions
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div className="flex">
              <div className="mr-4">
                <div className="bg-blue-600 p-3 rounded-lg">
                  <Award size={24} />
                </div>
              </div>
              <div>
                <h3 className="text-xl font-bold mb-2">Cost-Effective</h3>
                <p className="text-gray-300">
                  Enterprise-level AI capabilities at small business prices. Pay only for what you need, with scalable solutions that grow with your business.
                </p>
              </div>
            </div>
            <div className="flex">
              <div className="mr-4">
                <div className="bg-blue-600 p-3 rounded-lg">
                  <Users size={24} />
                </div>
              </div>
              <div>
                <h3 className="text-xl font-bold mb-2">User-Friendly</h3>
                <p className="text-gray-300">
                  No technical expertise required. Our solutions come with intuitive interfaces designed for business owners and their teams.
                </p>
              </div>
            </div>
            <div className="flex">
              <div className="mr-4">
                <div className="bg-blue-600 p-3 rounded-lg">
                  <Zap size={24} />
                </div>
              </div>
              <div>
                <h3 className="text-xl font-bold mb-2">Efficiency Boost</h3>
                <p className="text-gray-300">
                  Reduce manual work by up to 70% and increase decision-making speed by leveraging automated workflows and real-time insights.
                </p>
              </div>
            </div>
            <div className="flex">
              <div className="mr-4">
                <div className="bg-blue-600 p-3 rounded-lg">
                  <Workflow size={24} />
                </div>
              </div>
              <div>
                <h3 className="text-xl font-bold mb-2">Customized Approach</h3>
                <p className="text-gray-300">
                  Each solution is tailored to your specific business needs and industry, ensuring you get relevant results that make a difference.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Methodology Section */}
      <section id="methodology" className="py-16 px-4 md:px-12 max-w-6xl mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-16 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
          Our Implementation Process
        </h2>
        <div className="relative">
          {/* Timeline line */}
          <div className="hidden md:block absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-blue-600"></div>

          {/* Timeline items */}
          <div className="space-y-12 relative">
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2 md:pr-8 mb-4 md:mb-0 text-right">
                <h3 className="text-xl font-bold mb-2">1. Discovery & Analysis</h3>
                <p className="text-gray-300">
                  We analyze your business processes, identify pain points, and determine how AI can add the most value to your operations.
                </p>
              </div>
              <div className="md:w-1/2 flex justify-start">
                <div className="relative flex items-center justify-center">
                  <div className="h-8 w-8 bg-blue-600 rounded-full z-10 flex items-center justify-center">1</div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row-reverse items-center">
              <div className="md:w-1/2 md:pl-8 mb-4 md:mb-0 text-left">
                <h3 className="text-xl font-bold mb-2">2. Solution Design</h3>
                <p className="text-gray-300">
                  We create a customized AI solution blueprint that addresses your specific challenges and aligns with your business goals.
                </p>
              </div>
              <div className="md:w-1/2 flex justify-end">
                <div className="relative flex items-center justify-center">
                  <div className="h-8 w-8 bg-blue-600 rounded-full z-10 flex items-center justify-center">2</div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2 md:pr-8 mb-4 md:mb-0 text-right">
                <h3 className="text-xl font-bold mb-2">3. Implementation & Training</h3>
                <p className="text-gray-300">
                  We set up your AI solution, integrate it with your existing systems, and train your team on how to use it effectively.
                </p>
              </div>
              <div className="md:w-1/2 flex justify-start">
                <div className="relative flex items-center justify-center">
                  <div className="h-8 w-8 bg-blue-600 rounded-full z-10 flex items-center justify-center">3</div>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row-reverse items-center">
              <div className="md:w-1/2 md:pl-8 mb-4 md:mb-0 text-left">
                <h3 className="text-xl font-bold mb-2">4. Continuous Optimization</h3>
                <p className="text-gray-300">
                  We monitor performance, gather feedback, and fine-tune your solution to ensure it delivers maximum value over time.
                </p>
              </div>
              <div className="md:w-1/2 flex justify-end">
                <div className="relative flex items-center justify-center">
                  <div className="h-8 w-8 bg-blue-600 rounded-full z-10 flex items-center justify-center">4</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 px-4 text-center bg-gradient-to-r from-blue-600 to-purple-600">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold mb-6">Ready to Transform Your Business?</h2>
          <p className="text-xl mb-8">
            Start leveraging AI technology to increase efficiency, reduce costs, and gain competitive insights.
          </p>
          <button
            onClick={() => openModal('contact')}
            className="bg-white text-gray-900 hover:bg-gray-200 px-8 py-3 rounded-full font-semibold text-lg transition"
          >
            Schedule a Free Consultation
          </button>
        </div>
      </section>

      {/* Footer */}
      <footer id="contact" className="bg-gray-900 py-12 px-4">
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
        <div>
          <h3 className="text-xl font-bold mb-4">Mosyni AI</h3>
          <p className="text-gray-400">
            AI-powered solutions designed specifically for small businesses to compete and thrive.
          </p>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-4">Services</h3>
          <ul className="space-y-2 text-gray-400">
            <li><a href="/business-intelligence" className="hover:text-blue-400 transition">Business Intelligence</a></li>
            <li><a href="/process-automation" className="hover:text-blue-400 transition">Process Automation</a></li>
            <li><a href="/predictive-analytics" className="hover:text-blue-400 transition">Predictive Analysis</a></li>
            <li><a href="/customer-insights" className="hover:text-blue-400 transition">Customer Insights</a></li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-4">Company</h3>
          <ul className="space-y-2 text-gray-400">
            <li><a href="/about" className="hover:text-blue-400 transition">About Us</a></li>
            <li><a href="/about#expertise" className="hover:text-blue-400 transition">Our Expertise</a></li>
            <li><a href="/about#approach" className="hover:text-blue-400 transition">Our Approach</a></li>
            <li><a href="/about#methodology" className="hover:text-blue-400 transition">Methodology</a></li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-4">Contact</h3>
          <ul className="space-y-2 text-gray-400">
            <li>contact@mosyni.com</li>
            <li>Auckland, New Zealand</li>
          </ul>
        </div>
      </div>
      <div className="max-w-6xl mx-auto mt-8 pt-8 border-t border-gray-800 text-center text-gray-500">
        <p>© 2025 Mosyni AI. All rights reserved.</p>
      </div>
    </footer>

      {/* Contact Form Modal - Updated with status feedback */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-gray-800 rounded-lg w-full max-w-md relative">
            <button
              onClick={() => setShowModal(false)}
              className="absolute top-4 right-4 text-gray-400 hover:text-white"
              disabled={isSubmitting}
            >
              <CloseIcon size={24} />
            </button>

            <div className="p-6">
              <h3 className="text-2xl font-bold mb-6 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
                Get Started with Mosyni
                {formData.inquiryType === 'demo' && " - Demo Request"}
              </h3>

              {submitStatus.success === null ? (
                <form onSubmit={handleSubmit}>
                  <div className="space-y-4">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium mb-1">Full Name</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label htmlFor="email" className="block text-sm font-medium mb-1">Email Address</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label htmlFor="company" className="block text-sm font-medium mb-1">Company Name</label>
                      <input
                        type="text"
                        id="company"
                        name="company"
                        value={formData.company}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label htmlFor="phone" className="block text-sm font-medium mb-1">Phone Number</label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label htmlFor="message" className="block text-sm font-medium mb-1">How can we help you?</label>
                      <textarea
                        id="message"
                        name="message"
                        value={formData.inquiryType === 'demo' ? formData.message || "I'm interested in scheduling a demo of your AI services." : formData.message}
                        onChange={handleInputChange}
                        rows="3"
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      ></textarea>
                    </div>

                    <div className="pt-2">
                      <button
                        type="submit"
                        className="w-full bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-white font-bold py-3 px-4 rounded-md transition-all duration-200"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? 'Sending...' : 'Submit'}
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                <div className={`p-4 rounded-md mb-4 ${submitStatus.success ? 'bg-green-700 bg-opacity-30' : 'bg-red-700 bg-opacity-30'}`}>
                  <p className="text-center">
                    {submitStatus.message}
                  </p>
                </div>
              )}

              <p className="text-sm text-gray-400 mt-4 text-center">
                We'll get back to you within 24 hours.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AIServicesLandingPage;