import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AIServicesLandingPage from './components/AIServicesLandingPage';
import BusinessIntelligencePage from './components/BusinessIntelligencePage';
import ProcessAutomationPage from './components/ProcessAutomationPage';
import PredictiveAnalyticsPage from './components/PredictiveAnalyticsPage';
import CustomerInsightsPage from './components/CustomerInsightsPage';
import AboutPage from './components/AboutPage';

import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<AIServicesLandingPage />} />
          <Route path="/business-intelligence" element={<BusinessIntelligencePage />} />
          {/* Add additional routes for other service pages as they're created */}
          <Route path="/process-automation" element={<ProcessAutomationPage />} />
          <Route path="/predictive-analytics" element={<PredictiveAnalyticsPage />} />
          <Route path="/customer-insights" element={<CustomerInsightsPage />} />
          <Route path="/about" element={<AboutPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;