import emailjs from '@emailjs/browser';

// You'll need to replace these with your actual EmailJS credentials
// from your EmailJS dashboard (https://dashboard.emailjs.com/admin)
const SERVICE_ID = 'service_5w1kq6d';  // Get from EmailJS dashboard
const TEMPLATE_ID = 'template_hozxp91'; // Get from EmailJS dashboard
const PUBLIC_KEY = 'oQhutLSxiqQlmm7EN';   // Get from EmailJS dashboard

// Initialize EmailJS with your public key
emailjs.init(PUBLIC_KEY);

/**
 * Send form data via EmailJS
 * @param {Object} formData - The form data to send
 * @returns {Promise} - Promise resolved after email is sent
 */
export const sendContactForm = (formData) => {
  // Customize the template parameters based on your EmailJS template
  const templateParams = {
    to_email: 'jacobvinoo@gmail.com',
    from_name: formData.name,
    from_email: formData.email,
    company: formData.company,
    phone: formData.phone,
    message: formData.message,
    inquiry_type: formData.inquiryType
  };

  return emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams);
};