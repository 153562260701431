import React, { useState } from 'react';
import { BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, Bar } from 'recharts';
import { ChevronRight, Users, MessageSquare, Heart, ArrowRight, X as CloseIcon, Sparkles, LineChart, PieChart } from 'lucide-react';
import { sendContactForm } from '../services/emailService';

const CustomerInsightsPage = () => {
  // States for form and modal
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission state
  const [submitStatus, setSubmitStatus] = useState({ success: null, message: '' }); // Track submission status
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    phone: '',
    message: '',
    inquiryType: 'get-started' // Default inquiry type
  });

  // Navigation to home page
  const navigateToHome = () => {
    window.location.href = '/';
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Reset form data
  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      company: '',
      phone: '',
      message: '',
      inquiryType: 'get-started'
    });
  };

  // Open modal with specific inquiry type
  const openModal = (type) => {
    setFormData(prev => ({...prev, inquiryType: type}));
    setShowModal(true);
    // Reset status when opening modal
    setSubmitStatus({ success: null, message: '' });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Send form data via EmailJS
    sendContactForm(formData)
      .then((response) => {
        console.log('Email sent successfully!', response);
        setSubmitStatus({
          success: true,
          message: 'Thanks for your interest! We will contact you soon.'
        });
        // Clear form but don't close modal immediately to show success message
        resetForm();
        // Close modal after delay to show success message
        setTimeout(() => {
          setShowModal(false);
          setSubmitStatus({ success: null, message: '' });
        }, 3000);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        setSubmitStatus({
          success: false,
          message: 'There was an error sending your message. Please try again or contact us directly.'
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  // Sample data for charts
  const customerSegmentData = [
    { segment: 'Value Seekers', count: 450, retention: 72 },
    { segment: 'Loyal Enthusiasts', count: 320, retention: 94 },
    { segment: 'Occasional Buyers', count: 680, retention: 45 },
    { segment: 'New Explorers', count: 520, retention: 58 },
    { segment: 'Dormant Customers', count: 280, retention: 12 },
  ];

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Navigation */}
      <nav className="flex justify-between items-center px-6 py-4 lg:px-12 bg-gray-900">
        <div className="flex items-center cursor-pointer" onClick={navigateToHome}>
          <span className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">Mosyni AI</span>
        </div>
        <div className="hidden md:flex space-x-8">
          <a href="/#services" className="hover:text-blue-400 transition">Services</a>
          <a href="/#benefits" className="hover:text-blue-400 transition">Benefits</a>
          <a href="/#methodology" className="hover:text-blue-400 transition">Methodology</a>
          <a href="/#contact" className="hover:text-blue-400 transition">Contact</a>
        </div>
        <button className="bg-blue-600 hover:bg-blue-700 px-6 py-2 rounded-full font-semibold transition" onClick={navigateToHome}>
          Back to Home
        </button>
      </nav>

      {/* Hero Section */}
      <div className="bg-gradient-to-br from-green-800 to-gray-900">
        <div className="container mx-auto px-4 py-20 md:py-32">
          <div className="max-w-3xl">
            <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
              Customer Insights Solutions
            </h1>
            <p className="text-xl text-gray-300 mb-8">
              Deeply understand your customers' needs, behaviors, and preferences with AI-powered analytics. Our customer insights solutions help small businesses build stronger relationships, improve satisfaction, and drive loyalty.
            </p>
            <button
              onClick={() => openModal('get-started')}
              className="bg-blue-600 hover:bg-blue-700 px-8 py-3 rounded-full font-semibold text-lg transition flex items-center"
            >
              Get Started
              <ChevronRight className="ml-2" size={20} />
            </button>
          </div>
        </div>
      </div>

      {/* Key Features */}
      <div className="container mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold mb-12 text-center">How Our Customer Insights Transform Your Business</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-gray-800 rounded-xl p-8 hover:shadow-lg transition">
            <div className="p-3 bg-blue-600 rounded-lg inline-block mb-4">
              <Users size={24} />
            </div>
            <h3 className="text-xl font-bold mb-3">Customer Segmentation</h3>
            <p className="text-gray-300">
              Identify distinct customer groups based on behavior, preferences, and value with AI clustering that reveals hidden patterns in your customer data.
            </p>
          </div>
          <div className="bg-gray-800 rounded-xl p-8 hover:shadow-lg transition">
            <div className="p-3 bg-purple-600 rounded-lg inline-block mb-4">
              <MessageSquare size={24} />
            </div>
            <h3 className="text-xl font-bold mb-3">Sentiment Analysis</h3>
            <p className="text-gray-300">
              Understand customer feelings and opinions with advanced natural language processing that analyzes reviews, support tickets, and social media mentions.
            </p>
          </div>
          <div className="bg-gray-800 rounded-xl p-8 hover:shadow-lg transition">
            <div className="p-3 bg-pink-600 rounded-lg inline-block mb-4">
              <LineChart size={24} />
            </div>
            <h3 className="text-xl font-bold mb-3">Journey Mapping</h3>
            <p className="text-gray-300">
              Visualize customer interactions across all touchpoints to identify key moments of truth, pain points, and opportunities to enhance the customer experience.
            </p>
          </div>
        </div>
      </div>

      {/* Sample Visualization */}
      <div className="bg-gray-800 py-16">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4">Know Your Customers Better</h2>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Segment your customers based on behavior and preferences to deliver personalized experiences that resonate.
            </p>
          </div>
          <div className="bg-gray-900 rounded-xl p-4 md:p-8 shadow-lg">
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={customerSegmentData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                  <XAxis dataKey="segment" stroke="#888" />
                  <YAxis stroke="#888" />
                  <Tooltip
                    contentStyle={{ backgroundColor: '#333', border: 'none' }}
                    labelStyle={{ color: '#fff' }}
                  />
                  <Bar dataKey="count" fill="#4f46e5" name="Customer Count" />
                  <Bar dataKey="retention" fill="#06b6d4" name="Retention Rate (%)" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="container mx-auto px-4 py-16">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold mb-4">Benefits of Customer Insights</h2>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            Harness the power of data to build stronger customer relationships and drive business growth.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div className="flex">
            <div className="mr-6">
              <div className="p-3 bg-blue-600 rounded-full">
                <Heart size={24} />
              </div>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-2">Increased Customer Loyalty</h3>
              <p className="text-gray-300">
                Boost retention rates by 25-40% through personalized engagement strategies based on deep customer understanding and proactive relationship management.
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="mr-6">
              <div className="p-3 bg-blue-600 rounded-full">
                <Sparkles size={24} />
              </div>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-2">Enhanced Customer Experience</h3>
              <p className="text-gray-300">
                Create tailored experiences that address specific customer needs and preferences, resulting in higher satisfaction scores and positive word-of-mouth.
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="mr-6">
              <div className="p-3 bg-blue-600 rounded-full">
                <PieChart size={24} />
              </div>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-2">Optimized Marketing ROI</h3>
              <p className="text-gray-300">
                Improve marketing efficiency by 30-50% by targeting the right customers with the right messages at the right time, eliminating wasted ad spend.
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="mr-6">
              <div className="p-3 bg-blue-600 rounded-full">
                <Users size={24} />
              </div>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-2">Product Development Insights</h3>
              <p className="text-gray-300">
                Uncover unmet customer needs and preferences to guide product development and refinement, ensuring new offerings align with market demands.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-blue-600 to-purple-600 py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to Truly Understand Your Customers?</h2>
          <p className="text-xl mb-8 max-w-3xl mx-auto">
            Our customer insights experts will help you unlock the hidden value in your customer data and build stronger, more profitable relationships.
          </p>
          <button
            onClick={() => openModal('consultation')}
            className="bg-white text-gray-900 hover:bg-gray-100 px-8 py-3 rounded-full font-semibold text-lg transition flex items-center mx-auto"
          >
            Schedule a Free Consultation
            <ArrowRight className="ml-2" size={20} />
          </button>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="container mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold mb-12 text-center">Frequently Asked Questions</h2>
        <div className="max-w-3xl mx-auto space-y-6">
          <div className="bg-gray-800 rounded-lg p-6">
            <h3 className="text-xl font-bold mb-2">What customer data do you analyze?</h3>
            <p className="text-gray-300">
              We can analyze virtually any customer data you have, including purchase history, browsing behavior, demographic information, support interactions, survey responses, social media engagement, and more. Our platform can integrate with your CRM, e-commerce platform, customer service tools, and other systems to create a comprehensive view.
            </p>
          </div>
          <div className="bg-gray-800 rounded-lg p-6">
            <h3 className="text-xl font-bold mb-2">How do you ensure customer data privacy?</h3>
            <p className="text-gray-300">
              We take data privacy extremely seriously. All data is processed in compliance with GDPR, CCPA, and other relevant regulations. We implement enterprise-grade encryption, secure access controls, and data anonymization techniques. We never sell or share your customer data, and we provide transparent documentation of all data handling practices.
            </p>
          </div>
          <div className="bg-gray-800 rounded-lg p-6">
            <h3 className="text-xl font-bold mb-2">How quickly can I see actionable insights?</h3>
            <p className="text-gray-300">
              Initial insights can be available within 1-2 weeks of implementation, with more sophisticated analysis developing over time as the AI learns your business and customer patterns. We focus on delivering quick wins first, so you can start implementing changes and seeing results while more complex insights continue to develop.
            </p>
          </div>
          <div className="bg-gray-800 rounded-lg p-6">
            <h3 className="text-xl font-bold mb-2">Can I integrate insights with my marketing tools?</h3>
            <p className="text-gray-300">
              Absolutely. Our customer insights platform can integrate with your email marketing, CRM, ad platforms, and other marketing tools. This allows you to seamlessly activate insights through targeted campaigns, personalized communications, and tailored customer experiences across all touchpoints.
            </p>
          </div>
        </div>
      </div>
{/* Contact Form Modal */}
{showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-gray-800 rounded-lg w-full max-w-md relative">
            <button
              onClick={() => setShowModal(false)}
              className="absolute top-4 right-4 text-gray-400 hover:text-white"
              disabled={isSubmitting}
            >
              <CloseIcon size={24} />
            </button>

            <div className="p-6">
              <h3 className="text-2xl font-bold mb-6 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
                Get Started with Mosyni AI
                {formData.inquiryType === 'consultation' && " - Consultation Request"}
              </h3>

              {submitStatus.success === null ? (
                <form onSubmit={handleSubmit}>
                  <div className="space-y-4">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium mb-1">Full Name</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label htmlFor="email" className="block text-sm font-medium mb-1">Email Address</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label htmlFor="company" className="block text-sm font-medium mb-1">Company Name</label>
                      <input
                        type="text"
                        id="company"
                        name="company"
                        value={formData.company}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label htmlFor="phone" className="block text-sm font-medium mb-1">Phone Number</label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label htmlFor="message" className="block text-sm font-medium mb-1">How can we help you?</label>
                      <textarea
                        id="message"
                        name="message"
                        value={formData.inquiryType === 'consultation' ?
                          formData.message || "I'm interested in scheduling a consultation about Customer Insights solutions." :
                          formData.message}
                        onChange={handleInputChange}
                        rows="3"
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      ></textarea>
                    </div>

                    <div className="pt-2">
                      <button
                        type="submit"
                        className="w-full bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-white font-bold py-3 px-4 rounded-md transition-all duration-200"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? 'Sending...' : 'Submit'}
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                <div className={`p-4 rounded-md mb-4 ${submitStatus.success ? 'bg-green-700 bg-opacity-30' : 'bg-red-700 bg-opacity-30'}`}>
                  <p className="text-center">
                    {submitStatus.message}
                  </p>
                </div>
              )}

              <p className="text-sm text-gray-400 mt-4 text-center">
                We'll get back to you within 24 hours.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerInsightsPage;