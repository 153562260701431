import React, { useState } from 'react';
import { BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, Bar } from 'recharts';
import { ChevronRight, Zap, RefreshCw, BarChart2, Clock, ArrowRight, X as CloseIcon, Settings, FileCheck } from 'lucide-react';
import { sendContactForm } from '../services/emailService';

const ProcessAutomationPage = () => {
  // States for form and modal
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission state
  const [submitStatus, setSubmitStatus] = useState({ success: null, message: '' }); // Track submission status
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    phone: '',
    message: '',
    inquiryType: 'get-started' // Default inquiry type
  });

  // Navigation to home page
  const navigateToHome = () => {
    window.location.href = '/';
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Reset form data
  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      company: '',
      phone: '',
      message: '',
      inquiryType: 'get-started'
    });
  };

  // Open modal with specific inquiry type
  const openModal = (type) => {
    setFormData(prev => ({...prev, inquiryType: type}));
    setShowModal(true);
    // Reset status when opening modal
    setSubmitStatus({ success: null, message: '' });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Send form data via EmailJS
    sendContactForm(formData)
      .then((response) => {
        console.log('Email sent successfully!', response);
        setSubmitStatus({
          success: true,
          message: 'Thanks for your interest! We will contact you soon.'
        });
        // Clear form but don't close modal immediately to show success message
        resetForm();
        // Close modal after delay to show success message
        setTimeout(() => {
          setShowModal(false);
          setSubmitStatus({ success: null, message: '' });
        }, 3000);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        setSubmitStatus({
          success: false,
          message: 'There was an error sending your message. Please try again or contact us directly.'
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  // Sample data for charts
  const automationData = [
    { process: 'Customer Onboarding', beforeHours: 8.4, afterHours: 1.2 },
    { process: 'Invoice Processing', beforeHours: 6.7, afterHours: 0.5 },
    { process: 'Support Ticketing', beforeHours: 5.5, afterHours: 1.8 },
    { process: 'Data Entry', beforeHours: 12.2, afterHours: 0.3 },
    { process: 'Reporting', beforeHours: 9.8, afterHours: 2.1 },
  ];

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Navigation */}
      <nav className="flex justify-between items-center px-6 py-4 lg:px-12 bg-gray-900">
        <div className="flex items-center cursor-pointer" onClick={navigateToHome}>
          <span className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">Mosyni AI</span>
        </div>
        <div className="hidden md:flex space-x-8">
          <a href="/#services" className="hover:text-blue-400 transition">Services</a>
          <a href="/#benefits" className="hover:text-blue-400 transition">Benefits</a>
          <a href="/#methodology" className="hover:text-blue-400 transition">Methodology</a>
          <a href="/#contact" className="hover:text-blue-400 transition">Contact</a>
        </div>
        <button className="bg-blue-600 hover:bg-blue-700 px-6 py-2 rounded-full font-semibold transition" onClick={navigateToHome}>
          Back to Home
        </button>
      </nav>

      {/* Hero Section */}
      <div className="bg-gradient-to-br from-purple-900 to-gray-900">
        <div className="container mx-auto px-4 py-20 md:py-32">
          <div className="max-w-3xl">
            <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
              Process Automation Solutions
            </h1>
            <p className="text-xl text-gray-300 mb-8">
              Streamline operations and eliminate repetitive tasks with AI-powered automation. Our process automation solutions help small businesses boost efficiency, reduce errors, and free up valuable time for growth-focused activities.
            </p>
            <button
              onClick={() => openModal('get-started')}
              className="bg-blue-600 hover:bg-blue-700 px-8 py-3 rounded-full font-semibold text-lg transition flex items-center"
            >
              Get Started
              <ChevronRight className="ml-2" size={20} />
            </button>
          </div>
        </div>
      </div>

      {/* Key Features */}
      <div className="container mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold mb-12 text-center">How Our Automation Solutions Transform Your Business</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-gray-800 rounded-xl p-8 hover:shadow-lg transition">
            <div className="p-3 bg-blue-600 rounded-lg inline-block mb-4">
              <RefreshCw size={24} />
            </div>
            <h3 className="text-xl font-bold mb-3">Workflow Automation</h3>
            <p className="text-gray-300">
              Streamline complex business processes with intelligent workflows that automatically route tasks, documents, and approvals without manual intervention.
            </p>
          </div>
          <div className="bg-gray-800 rounded-xl p-8 hover:shadow-lg transition">
            <div className="p-3 bg-purple-600 rounded-lg inline-block mb-4">
              <FileCheck size={24} />
            </div>
            <h3 className="text-xl font-bold mb-3">Document Processing</h3>
            <p className="text-gray-300">
              Extract, categorize, and process information from documents using AI that learns to understand your specific business forms and paperwork.
            </p>
          </div>
          <div className="bg-gray-800 rounded-xl p-8 hover:shadow-lg transition">
            <div className="p-3 bg-pink-600 rounded-lg inline-block mb-4">
              <Settings size={24} />
            </div>
            <h3 className="text-xl font-bold mb-3">Integration Platform</h3>
            <p className="text-gray-300">
              Connect all your business applications with no-code integrations that enable seamless data flow between systems, eliminating data silos.
            </p>
          </div>
        </div>
      </div>

      {/* Sample Visualization */}
      <div className="bg-gray-800 py-16">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4">Hours Saved with Automation</h2>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              See how our automation solutions dramatically reduce the time spent on routine business processes.
            </p>
          </div>
          <div className="bg-gray-900 rounded-xl p-4 md:p-8 shadow-lg">
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={automationData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                  <XAxis dataKey="process" stroke="#888" />
                  <YAxis stroke="#888" label={{ value: 'Hours', angle: -90, position: 'insideLeft', fill: '#888' }} />
                  <Tooltip
                    contentStyle={{ backgroundColor: '#333', border: 'none' }}
                    labelStyle={{ color: '#fff' }}
                  />
                  <Bar dataKey="beforeHours" fill="#8884d8" name="Hours Before Automation" />
                  <Bar dataKey="afterHours" fill="#82ca9d" name="Hours After Automation" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="container mx-auto px-4 py-16">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold mb-4">Benefits of Process Automation</h2>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            Transform how your business operates with intelligent automation that delivers measurable results.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div className="flex">
            <div className="mr-6">
              <div className="p-3 bg-blue-600 rounded-full">
                <Clock size={24} />
              </div>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-2">Time Savings</h3>
              <p className="text-gray-300">
                Reduce time spent on routine tasks by 70-90%, freeing up your team to focus on creative, strategic work that drives business growth.
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="mr-6">
              <div className="p-3 bg-blue-600 rounded-full">
                <BarChart2 size={24} />
              </div>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-2">Increased Accuracy</h3>
              <p className="text-gray-300">
                Eliminate up to 95% of human errors in data entry, calculations, and routine processing, improving quality and reducing costly mistakes.
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="mr-6">
              <div className="p-3 bg-blue-600 rounded-full">
                <Zap size={24} />
              </div>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-2">Scalable Operations</h3>
              <p className="text-gray-300">
                Handle growth without proportional increases in staff by automating core processes that can scale to handle 5-10x your current workload.
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="mr-6">
              <div className="p-3 bg-blue-600 rounded-full">
                <RefreshCw size={24} />
              </div>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-2">Consistent Service</h3>
              <p className="text-gray-300">
                Deliver reliable, 24/7 service with automated processes that never sleep, ensuring consistent customer experiences regardless of volume.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-blue-600 to-purple-600 py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to Automate Your Business Processes?</h2>
          <p className="text-xl mb-8 max-w-3xl mx-auto">
            Our automation experts will help you identify the best opportunities for automation in your business and implement solutions that deliver rapid ROI.
          </p>
          <button
            onClick={() => openModal('consultation')}
            className="bg-white text-gray-900 hover:bg-gray-100 px-8 py-3 rounded-full font-semibold text-lg transition flex items-center mx-auto"
          >
            Schedule a Free Consultation
            <ArrowRight className="ml-2" size={20} />
          </button>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="container mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold mb-12 text-center">Frequently Asked Questions</h2>
        <div className="max-w-3xl mx-auto space-y-6">
          <div className="bg-gray-800 rounded-lg p-6">
            <h3 className="text-xl font-bold mb-2">What processes can be automated?</h3>
            <p className="text-gray-300">
              Almost any repetitive, rule-based process can be automated. Common examples include data entry, document processing, invoice management, customer onboarding, inventory updates, report generation, email responses, and approval workflows. We'll help you identify the processes that will deliver the highest ROI when automated.
            </p>
          </div>
          <div className="bg-gray-800 rounded-lg p-6">
            <h3 className="text-xl font-bold mb-2">How long does it take to implement automation?</h3>
            <p className="text-gray-300">
              Simple automation workflows can be implemented in as little as 2-3 weeks. More complex, integrated automation systems typically take 4-8 weeks. We follow an agile approach, delivering value incrementally so you can see results quickly while we build out the complete solution.
            </p>
          </div>
          <div className="bg-gray-800 rounded-lg p-6">
            <h3 className="text-xl font-bold mb-2">Do we need to replace our existing systems?</h3>
            <p className="text-gray-300">
              No. Our automation solutions are designed to work with your existing systems and software. We use API connections and modern integration techniques to automate processes across different platforms without requiring you to replace systems that are working well for your business.
            </p>
          </div>
          <div className="bg-gray-800 rounded-lg p-6">
            <h3 className="text-xl font-bold mb-2">How much technical knowledge do we need?</h3>
            <p className="text-gray-300">
              Very little. Our solutions are designed with user-friendly interfaces that require minimal technical expertise to operate. We provide comprehensive training and documentation, and our no-code/low-code approach means your team can make simple changes without developer assistance.
            </p>
          </div>
        </div>
      </div>

      {/* Contact Form Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-gray-800 rounded-lg w-full max-w-md relative">
            <button
              onClick={() => setShowModal(false)}
              className="absolute top-4 right-4 text-gray-400 hover:text-white"
              disabled={isSubmitting}
            >
              <CloseIcon size={24} />
            </button>

            <div className="p-6">
              <h3 className="text-2xl font-bold mb-6 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
                Get Started with Mosyni AI
                {formData.inquiryType === 'consultation' && " - Consultation Request"}
              </h3>

              {submitStatus.success === null ? (
                <form onSubmit={handleSubmit}>
                  <div className="space-y-4">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium mb-1">Full Name</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label htmlFor="email" className="block text-sm font-medium mb-1">Email Address</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label htmlFor="company" className="block text-sm font-medium mb-1">Company Name</label>
                      <input
                        type="text"
                        id="company"
                        name="company"
                        value={formData.company}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label htmlFor="phone" className="block text-sm font-medium mb-1">Phone Number</label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label htmlFor="message" className="block text-sm font-medium mb-1">How can we help you?</label>
                      <textarea
                        id="message"
                        name="message"
                        value={formData.inquiryType === 'consultation' ?
                          formData.message || "I'm interested in scheduling a consultation about Process Automation solutions." :
                          formData.message}
                        onChange={handleInputChange}
                        rows="3"
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      ></textarea>
                    </div>

                    <div className="pt-2">
                      <button
                        type="submit"
                        className="w-full bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-white font-bold py-3 px-4 rounded-md transition-all duration-200"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? 'Sending...' : 'Submit'}
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                <div className={`p-4 rounded-md mb-4 ${submitStatus.success ? 'bg-green-700 bg-opacity-30' : 'bg-red-700 bg-opacity-30'}`}>
                  <p className="text-center">
                    {submitStatus.message}
                  </p>
                </div>
              )}

              <p className="text-sm text-gray-400 mt-4 text-center">
                We'll get back to you within 24 hours.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProcessAutomationPage;