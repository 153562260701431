import React, { useState } from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ChevronRight, TrendingUp, Clock, Zap, PiggyBank, ArrowRight, X as CloseIcon, BarChart, Target } from 'lucide-react';
import { sendContactForm } from '../services/emailService';

const PredictiveAnalyticsPage = () => {
  // States for form and modal
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission state
  const [submitStatus, setSubmitStatus] = useState({ success: null, message: '' }); // Track submission status
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    phone: '',
    message: '',
    inquiryType: 'get-started' // Default inquiry type
  });

  // Navigation to home page
  const navigateToHome = () => {
    window.location.href = '/';
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Reset form data
  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      company: '',
      phone: '',
      message: '',
      inquiryType: 'get-started'
    });
  };

  // Open modal with specific inquiry type
  const openModal = (type) => {
    setFormData(prev => ({...prev, inquiryType: type}));
    setShowModal(true);
    // Reset status when opening modal
    setSubmitStatus({ success: null, message: '' });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Send form data via EmailJS
    sendContactForm(formData)
      .then((response) => {
        console.log('Email sent successfully!', response);
        setSubmitStatus({
          success: true,
          message: 'Thanks for your interest! We will contact you soon.'
        });
        // Clear form but don't close modal immediately to show success message
        resetForm();
        // Close modal after delay to show success message
        setTimeout(() => {
          setShowModal(false);
          setSubmitStatus({ success: null, message: '' });
        }, 3000);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        setSubmitStatus({
          success: false,
          message: 'There was an error sending your message. Please try again or contact us directly.'
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  // Sample data for charts
  const forecastData = [
    { month: 'Jan', actual: 4200, predicted: 4300 },
    { month: 'Feb', actual: 4500, predicted: 4400 },
    { month: 'Mar', actual: 4800, predicted: 4900 },
    { month: 'Apr', actual: 5100, predicted: 5000 },
    { month: 'May', actual: 5400, predicted: 5600 },
    { month: 'Jun', actual: 5800, predicted: 6000 },
    { month: 'Jul', actual: 6200, predicted: 6500 },
    { month: 'Aug', actual: null, predicted: 6800 },
    { month: 'Sep', actual: null, predicted: 7200 },
    { month: 'Oct', actual: null, predicted: 7600 },
    { month: 'Nov', actual: null, predicted: 8000 },
    { month: 'Dec', actual: null, predicted: 8500 },
  ];

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Navigation */}
      <nav className="flex justify-between items-center px-6 py-4 lg:px-12 bg-gray-900">
        <div className="flex items-center cursor-pointer" onClick={navigateToHome}>
          <span className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">Mosyni AI</span>
        </div>
        <div className="hidden md:flex space-x-8">
          <a href="/#services" className="hover:text-blue-400 transition">Services</a>
          <a href="/#benefits" className="hover:text-blue-400 transition">Benefits</a>
          <a href="/#methodology" className="hover:text-blue-400 transition">Methodology</a>
          <a href="/#contact" className="hover:text-blue-400 transition">Contact</a>
        </div>
        <button className="bg-blue-600 hover:bg-blue-700 px-6 py-2 rounded-full font-semibold transition" onClick={navigateToHome}>
          Back to Home
        </button>
      </nav>

      {/* Hero Section */}
      <div className="bg-gradient-to-br from-pink-900 to-gray-900">
        <div className="container mx-auto px-4 py-20 md:py-32">
          <div className="max-w-3xl">
            <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
              Predictive Analytics Solutions
            </h1>
            <p className="text-xl text-gray-300 mb-8">
              Anticipate market trends and customer behavior with AI-powered forecasting. Our predictive analytics helps small businesses gain foresight, minimize risks, and capitalize on future opportunities.
            </p>
            <button
              onClick={() => openModal('get-started')}
              className="bg-blue-600 hover:bg-blue-700 px-8 py-3 rounded-full font-semibold text-lg transition flex items-center"
            >
              Get Started
              <ChevronRight className="ml-2" size={20} />
            </button>
          </div>
        </div>
      </div>

      {/* Key Features */}
      <div className="container mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold mb-12 text-center">How Our Predictive Analytics Transform Your Business</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-gray-800 rounded-xl p-8 hover:shadow-lg transition">
            <div className="p-3 bg-blue-600 rounded-lg inline-block mb-4">
              <TrendingUp size={24} />
            </div>
            <h3 className="text-xl font-bold mb-3">Future Forecasting</h3>
            <p className="text-gray-300">
              Predict sales trends, customer demand, and market shifts with advanced machine learning models that learn from your historical data and industry patterns.
            </p>
          </div>
          <div className="bg-gray-800 rounded-xl p-8 hover:shadow-lg transition">
            <div className="p-3 bg-purple-600 rounded-lg inline-block mb-4">
              <BarChart size={24} />
            </div>
            <h3 className="text-xl font-bold mb-3">Customer Behavior Analysis</h3>
            <p className="text-gray-300">
              Understand customer purchase patterns, identify churn risks, and discover upsell opportunities with AI that predicts individual customer actions.
            </p>
          </div>
          <div className="bg-gray-800 rounded-xl p-8 hover:shadow-lg transition">
            <div className="p-3 bg-pink-600 rounded-lg inline-block mb-4">
              <Target size={24} />
            </div>
            <h3 className="text-xl font-bold mb-3">Risk Assessment</h3>
            <p className="text-gray-300">
              Identify potential risks in your business operations, supply chain, and market position with probability models that highlight vulnerabilities before they impact you.
            </p>
          </div>
        </div>
      </div>

      {/* Sample Visualization */}
      <div className="bg-gray-800 py-16">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4">See Into the Future</h2>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Our predictive models provide accurate forecasts that help you plan inventory, staffing, and marketing with confidence.
            </p>
          </div>
          <div className="bg-gray-900 rounded-xl p-4 md:p-8 shadow-lg">
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={forecastData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                  <XAxis dataKey="month" stroke="#888" />
                  <YAxis stroke="#888" label={{ value: 'Revenue ($)', angle: -90, position: 'insideLeft', fill: '#888' }} />
                  <Tooltip
                    contentStyle={{ backgroundColor: '#333', border: 'none' }}
                    labelStyle={{ color: '#fff' }}
                  />
                  <Line type="monotone" dataKey="actual" stroke="#8884d8" name="Actual Revenue" strokeWidth={2} />
                  <Line type="monotone" dataKey="predicted" stroke="#82ca9d" name="Predicted Revenue" strokeWidth={2} strokeDasharray="5 5" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="container mx-auto px-4 py-16">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold mb-4">Benefits of Predictive Analytics</h2>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            Gain the competitive advantage that comes with knowing what's likely to happen before it does.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div className="flex">
            <div className="mr-6">
              <div className="p-3 bg-blue-600 rounded-full">
                <Clock size={24} />
              </div>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-2">Proactive Decision Making</h3>
              <p className="text-gray-300">
                Move from reactive to proactive business management with early insights into market changes, customer needs, and operational challenges before they impact your business.
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="mr-6">
              <div className="p-3 bg-blue-600 rounded-full">
                <PiggyBank size={24} />
              </div>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-2">Optimized Resource Allocation</h3>
              <p className="text-gray-300">
                Reduce waste by up to 30% by allocating inventory, staffing, and marketing resources based on predicted demand rather than guesswork.
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="mr-6">
              <div className="p-3 bg-blue-600 rounded-full">
                <Zap size={24} />
              </div>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-2">Increased Revenue</h3>
              <p className="text-gray-300">
                Our clients typically see a 15-25% increase in sales through better targeting, personalized marketing, and optimized pricing strategies informed by predictive insights.
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="mr-6">
              <div className="p-3 bg-blue-600 rounded-full">
                <TrendingUp size={24} />
              </div>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-2">Risk Mitigation</h3>
              <p className="text-gray-300">
                Reduce business risks by identifying potential threats in advance, allowing you to develop contingency plans and avoid costly disruptions.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-blue-600 to-purple-600 py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to See the Future of Your Business?</h2>
          <p className="text-xl mb-8 max-w-3xl mx-auto">
            Our data scientists will help you implement predictive analytics that deliver actionable insights for your specific business needs.
          </p>
          <button
            onClick={() => openModal('consultation')}
            className="bg-white text-gray-900 hover:bg-gray-100 px-8 py-3 rounded-full font-semibold text-lg transition flex items-center mx-auto"
          >
            Schedule a Free Consultation
            <ArrowRight className="ml-2" size={20} />
          </button>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="container mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold mb-12 text-center">Frequently Asked Questions</h2>
        <div className="max-w-3xl mx-auto space-y-6">
          <div className="bg-gray-800 rounded-lg p-6">
            <h3 className="text-xl font-bold mb-2">Do I need a large amount of data to use predictive analytics?</h3>
            <p className="text-gray-300">
              While more data generally leads to better predictions, our models can work effectively with smaller datasets common in small businesses. We can also leverage industry benchmarks and market data to enhance your predictions when internal data is limited.
            </p>
          </div>
          <div className="bg-gray-800 rounded-lg p-6">
            <h3 className="text-xl font-bold mb-2">How accurate are the predictions?</h3>
            <p className="text-gray-300">
              Our models typically achieve 80-90% accuracy for short-term predictions, with accuracy decreasing for longer time horizons. We provide confidence intervals with all predictions and continuously refine models as new data becomes available.
            </p>
          </div>
          <div className="bg-gray-800 rounded-lg p-6">
            <h3 className="text-xl font-bold mb-2">What types of predictions can you make for my business?</h3>
            <p className="text-gray-300">
              We can develop models for sales forecasting, customer churn prediction, inventory optimization, price sensitivity, customer lifetime value, marketing campaign effectiveness, and many other business metrics specific to your industry and goals.
            </p>
          </div>
          <div className="bg-gray-800 rounded-lg p-6">
            <h3 className="text-xl font-bold mb-2">How long does it take to implement predictive analytics?</h3>
            <p className="text-gray-300">
              Basic models can be implemented in 3-4 weeks, while more complex predictive systems may take 2-3 months. We follow an iterative approach, delivering initial insights quickly and then refining and expanding the models over time.
            </p>
          </div>
        </div>
      </div>

      {/* Contact Form Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-gray-800 rounded-lg w-full max-w-md relative">
            <button
              onClick={() => setShowModal(false)}
              className="absolute top-4 right-4 text-gray-400 hover:text-white"
              disabled={isSubmitting}
            >
              <CloseIcon size={24} />
            </button>

            <div className="p-6">
              <h3 className="text-2xl font-bold mb-6 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
                Get Started with Mosyni AI
                {formData.inquiryType === 'consultation' && " - Consultation Request"}
              </h3>

              {submitStatus.success === null ? (
                <form onSubmit={handleSubmit}>
                  <div className="space-y-4">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium mb-1">Full Name</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label htmlFor="email" className="block text-sm font-medium mb-1">Email Address</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label htmlFor="company" className="block text-sm font-medium mb-1">Company Name</label>
                      <input
                        type="text"
                        id="company"
                        name="company"
                        value={formData.company}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label htmlFor="phone" className="block text-sm font-medium mb-1">Phone Number</label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label htmlFor="message" className="block text-sm font-medium mb-1">How can we help you?</label>
                      <textarea
                        id="message"
                        name="message"
                        value={formData.inquiryType === 'consultation' ?
                          formData.message || "I'm interested in scheduling a consultation about Predictive Analytics solutions." :
                          formData.message}
                        onChange={handleInputChange}
                        rows="3"
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      ></textarea>
                    </div>

                    <div className="pt-2">
                      <button
                        type="submit"
                        className="w-full bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-white font-bold py-3 px-4 rounded-md transition-all duration-200"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? 'Sending...' : 'Submit'}
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                <div className={`p-4 rounded-md mb-4 ${submitStatus.success ? 'bg-green-700 bg-opacity-30' : 'bg-red-700 bg-opacity-30'}`}>
                  <p className="text-center">
                    {submitStatus.message}
                  </p>
                </div>
              )}

              <p className="text-sm text-gray-400 mt-4 text-center">
                We'll get back to you within 24 hours.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PredictiveAnalyticsPage;