import React from 'react';

const AIDataViz = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 600" width="100%" height="100%" preserveAspectRatio="xMidYMid meet">
      <defs>
        {/* Gradients */}
        <linearGradient id="blueGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#4f46e5" />
          <stop offset="100%" stopColor="#06b6d4" />
        </linearGradient>
        <linearGradient id="purpleGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#8884d8" />
          <stop offset="100%" stopColor="#c084fc" />
        </linearGradient>
        <linearGradient id="pinkGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#ec4899" />
          <stop offset="100%" stopColor="#f472b6" />
        </linearGradient>

        {/* Filters for glow effects */}
        <filter id="blueGlow" x="-20%" y="-20%" width="140%" height="140%">
          <feGaussianBlur stdDeviation="8" result="blur" />
          <feComposite in="SourceGraphic" in2="blur" operator="over" />
        </filter>

        {/* Animation paths */}
        <path id="orbitPath1" d="M400,300 m-150,0 a150,100 0 1,0 300,0 a150,100 0 1,0 -300,0" fill="none" />
        <path id="orbitPath2" d="M400,300 m-100,0 a100,150 0 1,0 200,0 a100,150 0 1,0 -200,0" fill="none" />
        <path id="dataPath" d="M150,450 C250,350 350,450 400,300 S450,250 500,200 S550,180 650,150" fill="none" stroke="none" />
      </defs>

      {/* Central Node (AI Core) */}
      <g id="centralNode">
        <circle cx="400" cy="300" r="40" fill="url(#blueGradient)" opacity="0.9">
          <animate attributeName="opacity" values="0.7;0.9;0.7" dur="3s" repeatCount="indefinite" />
        </circle>
        <circle cx="400" cy="300" r="20" fill="#ffffff" opacity="0.8" />
        {/* Pulsing ring */}
        <circle cx="400" cy="300" r="45" fill="none" stroke="url(#blueGradient)" strokeWidth="2" opacity="0.6">
          <animate attributeName="r" values="45;60;45" dur="4s" repeatCount="indefinite" />
          <animate attributeName="opacity" values="0.6;0.2;0.6" dur="4s" repeatCount="indefinite" />
        </circle>
      </g>

      {/* Orbiting Data Nodes - Increased orbit size */}
      <g id="dataNodes">
        {/* First orbit - Expanded */}
        <g>
          <animateMotion dur="15s" repeatCount="indefinite" path="M400,300 m-200,0 a200,150 0 1,0 400,0 a200,150 0 1,0 -400,0" />
          <circle r="15" fill="url(#purpleGradient)" />
          <circle r="5" fill="#ffffff" opacity="0.8" />
        </g>
        <g>
          <animateMotion dur="15s" repeatCount="indefinite" path="M400,300 m-200,0 a200,150 0 1,0 400,0 a200,150 0 1,0 -400,0" begin="5s" />
          <circle r="10" fill="url(#purpleGradient)" />
          <circle r="3" fill="#ffffff" opacity="0.8" />
        </g>
        <g>
          <animateMotion dur="15s" repeatCount="indefinite" path="M400,300 m-200,0 a200,150 0 1,0 400,0 a200,150 0 1,0 -400,0" begin="10s" />
          <circle r="12" fill="url(#purpleGradient)" />
          <circle r="4" fill="#ffffff" opacity="0.8" />
        </g>

        {/* Second orbit (perpendicular) - Expanded */}
        <g>
          <animateMotion dur="20s" repeatCount="indefinite" path="M400,300 m-150,0 a150,200 0 1,0 300,0 a150,200 0 1,0 -300,0" />
          <circle r="12" fill="url(#pinkGradient)" />
          <circle r="4" fill="#ffffff" opacity="0.8" />
        </g>
        <g>
          <animateMotion dur="20s" repeatCount="indefinite" path="M400,300 m-150,0 a150,200 0 1,0 300,0 a150,200 0 1,0 -300,0" begin="7s" />
          <circle r="8" fill="url(#pinkGradient)" />
          <circle r="3" fill="#ffffff" opacity="0.8" />
        </g>
        <g>
          <animateMotion dur="20s" repeatCount="indefinite" path="M400,300 m-150,0 a150,200 0 1,0 300,0 a150,200 0 1,0 -300,0" begin="14s" />
          <circle r="10" fill="url(#pinkGradient)" />
          <circle r="3" fill="#ffffff" opacity="0.8" />
        </g>
      </g>

      {/* Connection Lines (Customer Insight paths) - Extended to reach edges */}
      <g id="connections">
        {/* Animated data path */}
        <path d="M100,500 C200,400 300,500 400,300 S500,200 600,150 S650,100 750,100" fill="none" stroke="url(#blueGradient)" strokeWidth="2" strokeDasharray="5,5" opacity="0.7">
          <animate attributeName="stroke-dashoffset" from="1000" to="0" dur="30s" repeatCount="indefinite" />
        </path>

        {/* Data points along the path */}
        <circle cx="100" cy="500" r="6" fill="#06b6d4">
          <animate attributeName="opacity" values="0.4;1;0.4" dur="3s" repeatCount="indefinite" />
        </circle>
        <circle cx="300" cy="500" r="8" fill="#06b6d4">
          <animate attributeName="opacity" values="0.4;1;0.4" dur="3s" repeatCount="indefinite" begin="0.5s" />
        </circle>
        <circle cx="400" cy="300" r="6" fill="#06b6d4">
          <animate attributeName="opacity" values="0.4;1;0.4" dur="3s" repeatCount="indefinite" begin="1s" />
        </circle>
        <circle cx="600" cy="150" r="8" fill="#06b6d4">
          <animate attributeName="opacity" values="0.4;1;0.4" dur="3s" repeatCount="indefinite" begin="1.5s" />
        </circle>
        <circle cx="750" cy="100" r="6" fill="#06b6d4">
          <animate attributeName="opacity" values="0.4;1;0.4" dur="3s" repeatCount="indefinite" begin="2s" />
        </circle>

        {/* Moving data points */}
        <circle r="4" fill="#ffffff">
          <animateMotion path="M100,500 C200,400 300,500 400,300 S500,200 600,150 S650,100 750,100" dur="5s" repeatCount="indefinite" />
          <animate attributeName="opacity" values="0;1;0" dur="5s" repeatCount="indefinite" />
        </circle>
        <circle r="4" fill="#ffffff">
          <animateMotion path="M100,500 C200,400 300,500 400,300 S500,200 600,150 S650,100 750,100" dur="5s" repeatCount="indefinite" begin="1.5s" />
          <animate attributeName="opacity" values="0;1;0" dur="5s" repeatCount="indefinite" begin="1.5s" />
        </circle>
        <circle r="4" fill="#ffffff">
          <animateMotion path="M100,500 C200,400 300,500 400,300 S500,200 600,150 S650,100 750,100" dur="5s" repeatCount="indefinite" begin="3s" />
          <animate attributeName="opacity" values="0;1;0" dur="5s" repeatCount="indefinite" begin="3s" />
        </circle>
      </g>

      {/* Neural Network Nodes (Data Science) - Extended to use more space */}
      <g id="neuralNet" opacity="0.9">
        {/* Layer 1 */}
        <circle cx="100" cy="200" r="10" fill="url(#purpleGradient)">
          <animate attributeName="r" values="10;12;10" dur="4s" repeatCount="indefinite" begin="0s" />
        </circle>
        <circle cx="100" cy="300" r="10" fill="url(#purpleGradient)">
          <animate attributeName="r" values="10;12;10" dur="4s" repeatCount="indefinite" begin="0.5s" />
        </circle>
        <circle cx="100" cy="400" r="10" fill="url(#purpleGradient)">
          <animate attributeName="r" values="10;12;10" dur="4s" repeatCount="indefinite" begin="1s" />
        </circle>

        {/* Layer 2 */}
        <circle cx="250" cy="275" r="10" fill="url(#purpleGradient)">
          <animate attributeName="r" values="10;12;10" dur="4s" repeatCount="indefinite" begin="1.5s" />
        </circle>
        <circle cx="250" cy="325" r="10" fill="url(#purpleGradient)">
          <animate attributeName="r" values="10;12;10" dur="4s" repeatCount="indefinite" begin="2s" />
        </circle>

        {/* Output Layer */}
        <circle cx="700" cy="300" r="10" fill="url(#purpleGradient)">
          <animate attributeName="r" values="10;12;10" dur="4s" repeatCount="indefinite" begin="2.5s" />
        </circle>

        {/* Connections */}
        <line x1="100" y1="200" x2="250" y2="275" stroke="#8884d8" strokeWidth="1" opacity="0.6">
          <animate attributeName="opacity" values="0.2;0.6;0.2" dur="3s" repeatCount="indefinite" />
        </line>
        <line x1="100" y1="200" x2="250" y2="325" stroke="#8884d8" strokeWidth="1" opacity="0.6">
          <animate attributeName="opacity" values="0.2;0.6;0.2" dur="3s" repeatCount="indefinite" begin="0.3s" />
        </line>
        <line x1="100" y1="300" x2="250" y2="275" stroke="#8884d8" strokeWidth="1" opacity="0.6">
          <animate attributeName="opacity" values="0.2;0.6;0.2" dur="3s" repeatCount="indefinite" begin="0.6s" />
        </line>
        <line x1="100" y1="300" x2="250" y2="325" stroke="#8884d8" strokeWidth="1" opacity="0.6">
          <animate attributeName="opacity" values="0.2;0.6;0.2" dur="3s" repeatCount="indefinite" begin="0.9s" />
        </line>
        <line x1="100" y1="400" x2="250" y2="275" stroke="#8884d8" strokeWidth="1" opacity="0.6">
          <animate attributeName="opacity" values="0.2;0.6;0.2" dur="3s" repeatCount="indefinite" begin="1.2s" />
        </line>
        <line x1="100" y1="400" x2="250" y2="325" stroke="#8884d8" strokeWidth="1" opacity="0.6">
          <animate attributeName="opacity" values="0.2;0.6;0.2" dur="3s" repeatCount="indefinite" begin="1.5s" />
        </line>
        <line x1="250" y1="275" x2="400" y2="300" stroke="#8884d8" strokeWidth="1" opacity="0.6">
          <animate attributeName="opacity" values="0.2;0.6;0.2" dur="3s" repeatCount="indefinite" begin="1.8s" />
        </line>
        <line x1="250" y1="325" x2="400" y2="300" stroke="#8884d8" strokeWidth="1" opacity="0.6">
          <animate attributeName="opacity" values="0.2;0.6;0.2" dur="3s" repeatCount="indefinite" begin="2.1s" />
        </line>
        <line x1="400" y1="300" x2="700" y2="300" stroke="#8884d8" strokeWidth="1" opacity="0.6">
          <animate attributeName="opacity" values="0.2;0.6;0.2" dur="3s" repeatCount="indefinite" begin="2.4s" />
        </line>

        {/* Connection data flow */}
        <circle r="3" fill="#ffffff">
          <animateMotion path="M100,200 L250,275 L400,300 L700,300" dur="2.5s" repeatCount="indefinite" />
          <animate attributeName="opacity" values="0;1;0" dur="2.5s" repeatCount="indefinite" />
        </circle>
        <circle r="3" fill="#ffffff">
          <animateMotion path="M100,300 L250,325 L400,300 L700,300" dur="2.5s" repeatCount="indefinite" begin="0.8s" />
          <animate attributeName="opacity" values="0;1;0" dur="2.5s" repeatCount="indefinite" begin="0.8s" />
        </circle>
        <circle r="3" fill="#ffffff">
          <animateMotion path="M100,400 L250,275 L400,300 L700,300" dur="2.5s" repeatCount="indefinite" begin="1.6s" />
          <animate attributeName="opacity" values="0;1;0" dur="2.5s" repeatCount="indefinite" begin="1.6s" />
        </circle>
      </g>

      {/* Customer Insights Visualization - Moved to bottom right corner */}
      <g id="customerInsights" transform="translate(650, 450)">
        {/* Circular chart representing customer segments */}
        <circle cx="0" cy="0" r="60" fill="none" stroke="#06b6d4" strokeWidth="10" strokeDasharray="75 25" strokeDashoffset="0" opacity="0.6">
          <animate attributeName="stroke-dashoffset" from="0" to="100" dur="20s" repeatCount="indefinite" />
          <animate attributeName="opacity" values="0.6;0.8;0.6" dur="5s" repeatCount="indefinite" />
        </circle>
        <circle cx="0" cy="0" r="50" fill="none" stroke="#ec4899" strokeWidth="8" strokeDasharray="50 50" strokeDashoffset="25" opacity="0.6">
          <animate attributeName="stroke-dashoffset" from="25" to="125" dur="15s" repeatCount="indefinite" />
          <animate attributeName="opacity" values="0.6;0.8;0.6" dur="5s" repeatCount="indefinite" begin="1s" />
        </circle>
        <circle cx="0" cy="0" r="40" fill="none" stroke="#8884d8" strokeWidth="6" strokeDasharray="40 60" strokeDashoffset="50" opacity="0.6">
          <animate attributeName="stroke-dashoffset" from="50" to="150" dur="10s" repeatCount="indefinite" />
          <animate attributeName="opacity" values="0.6;0.8;0.6" dur="5s" repeatCount="indefinite" begin="2s" />
        </circle>

        {/* Radial connections to center */}
        <line x1="0" y1="0" x2="45" y2="-45" stroke="#06b6d4" strokeWidth="2" opacity="0.5">
          <animate attributeName="opacity" values="0.3;0.7;0.3" dur="3s" repeatCount="indefinite" />
        </line>
        <line x1="0" y1="0" x2="45" y2="45" stroke="#ec4899" strokeWidth="2" opacity="0.5">
          <animate attributeName="opacity" values="0.3;0.7;0.3" dur="3s" repeatCount="indefinite" begin="1s" />
        </line>
        <line x1="0" y1="0" x2="-45" y2="45" stroke="#8884d8" strokeWidth="2" opacity="0.5">
          <animate attributeName="opacity" values="0.3;0.7;0.3" dur="3s" repeatCount="indefinite" begin="2s" />
        </line>
        <line x1="0" y1="0" x2="-45" y2="-45" stroke="#06b6d4" strokeWidth="2" opacity="0.5">
          <animate attributeName="opacity" values="0.3;0.7;0.3" dur="3s" repeatCount="indefinite" begin="1.5s" />
        </line>

        {/* Data nodes at connection endpoints */}
        <circle cx="45" cy="-45" r="5" fill="#06b6d4">
          <animate attributeName="r" values="5;7;5" dur="3s" repeatCount="indefinite" />
        </circle>
        <circle cx="45" cy="45" r="5" fill="#ec4899">
          <animate attributeName="r" values="5;7;5" dur="3s" repeatCount="indefinite" begin="1s" />
        </circle>
        <circle cx="-45" cy="45" r="5" fill="#8884d8">
          <animate attributeName="r" values="5;7;5" dur="3s" repeatCount="indefinite" begin="2s" />
        </circle>
        <circle cx="-45" cy="-45" r="5" fill="#06b6d4">
          <animate attributeName="r" values="5;7;5" dur="3s" repeatCount="indefinite" begin="1.5s" />
        </circle>
      </g>

      {/* Additional data points on the edges */}
      <g>
        <circle cx="50" cy="50" r="8" fill="url(#purpleGradient)">
          <animate attributeName="r" values="8;10;8" dur="3s" repeatCount="indefinite" begin="0.2s" />
          <animate attributeName="opacity" values="0.5;0.8;0.5" dur="3s" repeatCount="indefinite" begin="0.2s" />
        </circle>
        <circle cx="750" cy="550" r="8" fill="url(#purpleGradient)">
          <animate attributeName="r" values="8;10;8" dur="3s" repeatCount="indefinite" begin="1.2s" />
          <animate attributeName="opacity" values="0.5;0.8;0.5" dur="3s" repeatCount="indefinite" begin="1.2s" />
        </circle>
        <circle cx="50" cy="550" r="8" fill="url(#pinkGradient)">
          <animate attributeName="r" values="8;10;8" dur="3s" repeatCount="indefinite" begin="0.7s" />
          <animate attributeName="opacity" values="0.5;0.8;0.5" dur="3s" repeatCount="indefinite" begin="0.7s" />
        </circle>
        <circle cx="750" cy="50" r="8" fill="url(#pinkGradient)">
          <animate attributeName="r" values="8;10;8" dur="3s" repeatCount="indefinite" begin="1.7s" />
          <animate attributeName="opacity" values="0.5;0.8;0.5" dur="3s" repeatCount="indefinite" begin="1.7s" />
        </circle>
      </g>

      {/* Glowing outlines to enhance 3D effect */}
      <g filter="url(#blueGlow)" opacity="0.3">
        <path d="M400,300 m-200,0 a200,150 0 1,0 400,0 a200,150 0 1,0 -400,0" fill="none" stroke="#4f46e5" strokeWidth="1" />
        <path d="M400,300 m-150,0 a150,200 0 1,0 300,0 a150,200 0 1,0 -300,0" fill="none" stroke="#4f46e5" strokeWidth="1" />
      </g>
    </svg>
  );
};

export default AIDataViz;