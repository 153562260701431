import React, { useState } from 'react';
import { ArrowRight, X as CloseIcon, Lightbulb, Users, Layers, GitBranch, Code, Shield, Award, Brain } from 'lucide-react';
import { sendContactForm } from '../services/emailService';

const AboutPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission state
  const [submitStatus, setSubmitStatus] = useState({ success: null, message: '' }); // Track submission status
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    phone: '',
    message: '',
    inquiryType: 'contact' // Default inquiry type
  });

  // Navigation to home page
  const navigateToHome = () => {
    window.location.href = '/';
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Reset form data
  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      company: '',
      phone: '',
      message: '',
      inquiryType: 'contact'
    });
  };

  // Open modal with specific inquiry type
  const openModal = (type) => {
    setFormData(prev => ({...prev, inquiryType: type}));
    setShowModal(true);
    // Reset status when opening modal
    setSubmitStatus({ success: null, message: '' });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Send form data via EmailJS
    sendContactForm(formData)
      .then((response) => {
        console.log('Email sent successfully!', response);
        setSubmitStatus({
          success: true,
          message: 'Thanks for your interest! We will contact you soon.'
        });
        // Clear form but don't close modal immediately to show success message
        resetForm();
        // Close modal after delay to show success message
        setTimeout(() => {
          setShowModal(false);
          setSubmitStatus({ success: null, message: '' });
        }, 3000);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        setSubmitStatus({
          success: false,
          message: 'There was an error sending your message. Please try again or contact us directly.'
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Navigation */}
      <nav className="flex justify-between items-center px-6 py-4 lg:px-12 bg-gray-900">
        <div className="flex items-center cursor-pointer" onClick={navigateToHome}>
          <span className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">Mosyni AI</span>
        </div>
        <div className="hidden md:flex space-x-8">
          <a href="/#services" className="hover:text-blue-400 transition">Services</a>
          <a href="/#benefits" className="hover:text-blue-400 transition">Benefits</a>
          <a href="/#methodology" className="hover:text-blue-400 transition">Methodology</a>
          <a href="/#contact" className="hover:text-blue-400 transition">Contact</a>
        </div>
        <button className="bg-blue-600 hover:bg-blue-700 px-6 py-2 rounded-full font-semibold transition" onClick={navigateToHome}>
          Back to Home
        </button>
      </nav>

      {/* Hero Section */}
      <div className="bg-gradient-to-br from-gray-800 to-gray-900">
        <div className="container mx-auto px-4 py-20 md:py-28">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
              About Mosyni AI
            </h1>
            <p className="text-xl text-gray-300 mb-8">
              We're a team of AI experts dedicated to helping small businesses harness the transformative power of artificial intelligence through practical, value-driven solutions.
            </p>
          </div>
        </div>
      </div>

      {/* Our Expertise Section */}
      <div className="container mx-auto px-4 py-16">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold mb-12 text-center">Our AI Expertise</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            <div className="flex">
              <div className="mr-6">
                <div className="p-3 bg-blue-600 rounded-lg">
                  <Brain size={24} />
                </div>
              </div>
              <div>
                <h3 className="text-xl font-bold mb-2">Large Language Models (LLMs)</h3>
                <p className="text-gray-300">
                  We're experts in leveraging cutting-edge LLMs to solve business challenges, from automated customer support to content generation and data analysis.
                </p>
              </div>
            </div>
            <div className="flex">
              <div className="mr-6">
                <div className="p-3 bg-blue-600 rounded-lg">
                  <GitBranch size={24} />
                </div>
              </div>
              <div>
                <h3 className="text-xl font-bold mb-2">AI Agents & Automation</h3>
                <p className="text-gray-300">
                  We design intelligent AI agents that can automate complex workflows, make decisions, and integrate with your existing business systems.
                </p>
              </div>
            </div>
            <div className="flex">
              <div className="mr-6">
                <div className="p-3 bg-blue-600 rounded-lg">
                  <Shield size={24} />
                </div>
              </div>
              <div>
                <h3 className="text-xl font-bold mb-2">Private AI Environments</h3>
                <p className="text-gray-300">
                  We build secure, private AI solutions that keep your sensitive data protected while delivering powerful capabilities within your controlled environment.
                </p>
              </div>
            </div>
            <div className="flex">
              <div className="mr-6">
                <div className="p-3 bg-blue-600 rounded-lg">
                  <Code size={24} />
                </div>
              </div>
              <div>
                <h3 className="text-xl font-bold mb-2">Public AI Integration</h3>
                <p className="text-gray-300">
                  We expertly integrate public AI services and APIs to create cost-effective solutions that deliver immediate value without extensive infrastructure investments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Our Approach Section */}
      <div className="bg-gray-800 py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-3xl font-bold mb-12 text-center">Our Value-First Approach</h2>
            <div className="space-y-12">
              <div className="flex flex-col md:flex-row items-center bg-gray-900 rounded-xl p-6 md:p-8">
                <div className="md:w-1/4 mb-6 md:mb-0 flex justify-center">
                  <div className="p-4 bg-blue-600 rounded-full">
                    <Lightbulb size={48} />
                  </div>
                </div>
                <div className="md:w-3/4 md:pl-6">
                  <h3 className="text-xl font-bold mb-3">Business Understanding First</h3>
                  <p className="text-gray-300">
                    We start by deeply understanding your business challenges, goals, and processes. Rather than pushing technology for its own sake, we identify specific areas where AI can deliver measurable value and ROI. This ensures all solutions are aligned with your strategic objectives.
                  </p>
                </div>
              </div>

              <div className="flex flex-col md:flex-row items-center bg-gray-900 rounded-xl p-6 md:p-8">
                <div className="md:w-1/4 mb-6 md:mb-0 flex justify-center">
                  <div className="p-4 bg-purple-600 rounded-full">
                    <Layers size={48} />
                  </div>
                </div>
                <div className="md:w-3/4 md:pl-6">
                  <h3 className="text-xl font-bold mb-3">Targeted Implementation</h3>
                  <p className="text-gray-300">
                    We don't believe in one-size-fits-all AI. Instead, we implement targeted solutions for specific high-value use cases, allowing you to see quick wins while building toward a comprehensive AI strategy. Our iterative approach minimizes risk and maximizes return.
                  </p>
                </div>
              </div>

              <div className="flex flex-col md:flex-row items-center bg-gray-900 rounded-xl p-6 md:p-8">
                <div className="md:w-1/4 mb-6 md:mb-0 flex justify-center">
                  <div className="p-4 bg-pink-600 rounded-full">
                    <Users size={48} />
                  </div>
                </div>
                <div className="md:w-3/4 md:pl-6">
                  <h3 className="text-xl font-bold mb-3">Human-Centered Design</h3>
                  <p className="text-gray-300">
                    We design AI solutions with humans at the center, ensuring they enhance rather than replace your team's capabilities. Our focus is on creating intuitive, accessible tools that empower your employees and create seamless experiences for your customers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Analysis Tools Section */}
      <div className="container mx-auto px-4 py-16">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold mb-12 text-center">Our Analysis Methodology</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-gray-800 rounded-xl p-8 hover:shadow-lg transition">
              <h3 className="text-xl font-bold mb-3">Lean Business Process Analysis</h3>
              <p className="text-gray-300 mb-4">
                We identify and eliminate waste in your business processes, focusing on creating streamlined workflows that deliver maximum value with minimum resources.
              </p>
              <ul className="text-gray-300 space-y-2 list-disc pl-5">
                <li>Value stream mapping</li>
                <li>Process efficiency measurement</li>
                <li>Bottleneck identification</li>
                <li>Waste reduction strategies</li>
              </ul>
            </div>

            <div className="bg-gray-800 rounded-xl p-8 hover:shadow-lg transition">
              <h3 className="text-xl font-bold mb-3">Data Readiness Assessment</h3>
              <p className="text-gray-300 mb-4">
                We evaluate your existing data infrastructure, quality, and governance to ensure you have the foundation needed for successful AI implementation.
              </p>
              <ul className="text-gray-300 space-y-2 list-disc pl-5">
                <li>Data quality analysis</li>
                <li>Infrastructure evaluation</li>
                <li>Data governance review</li>
                <li>Integration capability assessment</li>
              </ul>
            </div>

            <div className="bg-gray-800 rounded-xl p-8 hover:shadow-lg transition">
              <h3 className="text-xl font-bold mb-3">ROI Forecasting Framework</h3>
              <p className="text-gray-300 mb-4">
                We use a sophisticated modeling approach to predict and measure the financial impact of AI implementations across various timeframes.
              </p>
              <ul className="text-gray-300 space-y-2 list-disc pl-5">
                <li>Cost-benefit analysis</li>
                <li>Time-to-value modeling</li>
                <li>Risk assessment</li>
                <li>Performance benchmarking</li>
              </ul>
            </div>

            <div className="bg-gray-800 rounded-xl p-8 hover:shadow-lg transition">
              <h3 className="text-xl font-bold mb-3">Design Thinking Workshops</h3>
              <p className="text-gray-300 mb-4">
                We facilitate collaborative sessions to identify user needs, generate innovative solutions, and rapidly prototype AI tools for quick validation.
              </p>
              <ul className="text-gray-300 space-y-2 list-disc pl-5">
                <li>Empathy mapping</li>
                <li>Problem framing</li>
                <li>Solution ideation</li>
                <li>Rapid prototyping and testing</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Team Section */}
      <div className="bg-gradient-to-br from-blue-900 to-gray-900 py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-3xl font-bold mb-12 text-center">Why Choose Mosyni AI</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-gray-800 bg-opacity-60 p-6 rounded-xl text-center">
                <div className="inline-block p-3 bg-blue-600 rounded-full mb-4">
                  <Award size={28} />
                </div>
                <h3 className="text-xl font-bold mb-2">AI Expertise</h3>
                <p className="text-gray-300">
                  Our team brings deep expertise in the latest AI technologies, with experience implementing solutions across diverse industries.
                </p>
              </div>

              <div className="bg-gray-800 bg-opacity-60 p-6 rounded-xl text-center">
                <div className="inline-block p-3 bg-purple-600 rounded-full mb-4">
                  <Users size={28} />
                </div>
                <h3 className="text-xl font-bold mb-2">Small Business Focus</h3>
                <p className="text-gray-300">
                  We specialize in right-sized AI solutions that deliver enterprise-level capabilities scaled appropriately for small and mid-sized businesses.
                </p>
              </div>

              <div className="bg-gray-800 bg-opacity-60 p-6 rounded-xl text-center">
                <div className="inline-block p-3 bg-pink-600 rounded-full mb-4">
                  <Lightbulb size={28} />
                </div>
                <h3 className="text-xl font-bold mb-2">Results-Driven</h3>
                <p className="text-gray-300">
                  We measure our success by the tangible business outcomes we deliver—whether that's cost savings, revenue growth, or operational improvements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-blue-600 to-purple-600 py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to Transform Your Business with AI?</h2>
          <p className="text-xl mb-8 max-w-3xl mx-auto">
            Let's discuss how our AI expertise can help you achieve your business goals with practical, value-driven solutions.
          </p>
          <button
            onClick={() => openModal('contact')}
            className="bg-white text-gray-900 hover:bg-gray-100 px-8 py-3 rounded-full font-semibold text-lg transition flex items-center mx-auto"
          >
            Schedule a Consultation
            <ArrowRight className="ml-2" size={20} />
          </button>
        </div>
      </div>

      {/* Contact Form Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-gray-800 rounded-lg w-full max-w-md relative">
            <button
              onClick={() => setShowModal(false)}
              className="absolute top-4 right-4 text-gray-400 hover:text-white"
              disabled={isSubmitting}
            >
              <CloseIcon size={24} />
            </button>

            <div className="p-6">
              <h3 className="text-2xl font-bold mb-6 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
                Contact Mosyni AI
              </h3>

              {submitStatus.success === null ? (
                <form onSubmit={handleSubmit}>
                  <div className="space-y-4">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium mb-1">Full Name</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label htmlFor="email" className="block text-sm font-medium mb-1">Email Address</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label htmlFor="company" className="block text-sm font-medium mb-1">Company Name</label>
                      <input
                        type="text"
                        id="company"
                        name="company"
                        value={formData.company}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label htmlFor="phone" className="block text-sm font-medium mb-1">Phone Number</label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label htmlFor="message" className="block text-sm font-medium mb-1">How can we help you?</label>
                      <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        rows="3"
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      ></textarea>
                    </div>

                    <div className="pt-2">
                      <button
                        type="submit"
                        className="w-full bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-white font-bold py-3 px-4 rounded-md transition-all duration-200"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? 'Sending...' : 'Submit'}
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                <div className={`p-4 rounded-md mb-4 ${submitStatus.success ? 'bg-green-700 bg-opacity-30' : 'bg-red-700 bg-opacity-30'}`}>
                  <p className="text-center">
                    {submitStatus.message}
                  </p>
                </div>
              )}

              <p className="text-sm text-gray-400 mt-4 text-center">
                We'll get back to you within 24 hours.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AboutPage;