import React, { useState } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ChevronRight, Database, PieChart, TrendingUp, Zap, ArrowRight, X as CloseIcon } from 'lucide-react';
import { sendContactForm } from '../services/emailService'; // Import the email service

const BusinessIntelligencePage = () => {
  // States for form and modal
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission state
  const [submitStatus, setSubmitStatus] = useState({ success: null, message: '' }); // Track submission status
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    phone: '',
    message: '',
    inquiryType: 'get-started' // Default inquiry type
  });

  // Navigation to home page
  const navigateToHome = () => {
    window.location.href = '/';
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Reset form data
  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      company: '',
      phone: '',
      message: '',
      inquiryType: 'get-started'
    });
  };

  // Open modal with specific inquiry type
  const openModal = (type) => {
    setFormData(prev => ({...prev, inquiryType: type}));
    setShowModal(true);
    // Reset status when opening modal
    setSubmitStatus({ success: null, message: '' });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Send form data via EmailJS
    sendContactForm(formData)
      .then((response) => {
        console.log('Email sent successfully!', response);
        setSubmitStatus({
          success: true,
          message: 'Thanks for your interest! We will contact you soon.'
        });
        // Clear form but don't close modal immediately to show success message
        resetForm();
        // Close modal after delay to show success message
        setTimeout(() => {
          setShowModal(false);
          setSubmitStatus({ success: null, message: '' });
        }, 3000);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        setSubmitStatus({
          success: false,
          message: 'There was an error sending your message. Please try again or contact us directly.'
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  // Sample data for charts
  const data = [
    { name: 'Jan', value: 4000 },
    { name: 'Feb', value: 3000 },
    { name: 'Mar', value: 5000 },
    { name: 'Apr', value: 2780 },
    { name: 'May', value: 1890 },
    { name: 'Jun', value: 2390 },
  ];

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Navigation */}
      <nav className="flex justify-between items-center px-6 py-4 lg:px-12 bg-gray-900">
        <div className="flex items-center cursor-pointer" onClick={navigateToHome}>
          <span className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">Mosyni AI</span>
        </div>
        <div className="hidden md:flex space-x-8">
          <a href="/#services" className="hover:text-blue-400 transition">Services</a>
          <a href="/#benefits" className="hover:text-blue-400 transition">Benefits</a>
          <a href="/#methodology" className="hover:text-blue-400 transition">Methodology</a>
          <a href="/#contact" className="hover:text-blue-400 transition">Contact</a>
        </div>
        <button className="bg-blue-600 hover:bg-blue-700 px-6 py-2 rounded-full font-semibold transition" onClick={navigateToHome}>
          Back to Home
        </button>
      </nav>

      {/* Hero Section */}
      <div className="bg-gradient-to-br from-blue-900 to-gray-900">
        <div className="container mx-auto px-4 py-20 md:py-32">
          <div className="max-w-3xl">
            <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
              Business Intelligence Solutions
            </h1>
            <p className="text-xl text-gray-300 mb-8">
              Transform raw data into actionable insights. Our AI-powered business intelligence solutions help small businesses make smarter decisions, identify opportunities, and drive growth.
            </p>
            <button
              onClick={() => openModal('get-started')}
              className="bg-blue-600 hover:bg-blue-700 px-8 py-3 rounded-full font-semibold text-lg transition flex items-center"
            >
              Get Started
              <ChevronRight className="ml-2" size={20} />
            </button>
          </div>
        </div>
      </div>

      {/* Key Features */}
      <div className="container mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold mb-12 text-center">How Our BI Solutions Help Your Business</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-gray-800 rounded-xl p-8 hover:shadow-lg transition">
            <div className="p-3 bg-blue-600 rounded-lg inline-block mb-4">
              <Database size={24} />
            </div>
            <h3 className="text-xl font-bold mb-3">Data Integration</h3>
            <p className="text-gray-300">
              Seamlessly connect all your data sources, from spreadsheets to CRMs and ERPs, into a single unified view with automated data cleaning and preparation.
            </p>
          </div>
          <div className="bg-gray-800 rounded-xl p-8 hover:shadow-lg transition">
            <div className="p-3 bg-purple-600 rounded-lg inline-block mb-4">
              <PieChart size={24} />
            </div>
            <h3 className="text-xl font-bold mb-3">Interactive Dashboards</h3>
            <p className="text-gray-300">
              Custom-built, intuitive dashboards that visualize your most important metrics in real-time, enabling faster and more informed decision making.
            </p>
          </div>
          <div className="bg-gray-800 rounded-xl p-8 hover:shadow-lg transition">
            <div className="p-3 bg-pink-600 rounded-lg inline-block mb-4">
              <TrendingUp size={24} />
            </div>
            <h3 className="text-xl font-bold mb-3">Automated Reporting</h3>
            <p className="text-gray-300">
              Schedule and automatically generate reports with AI-driven insights, saving hours of manual analysis and highlighting key trends and anomalies.
            </p>
          </div>
        </div>
      </div>

      {/* Sample Visualization */}
      <div className="bg-gray-800 py-16">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4">Visualize Your Data</h2>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Transform complex data into clear, actionable visualizations that help you spot trends and make better decisions.
            </p>
          </div>
          <div className="bg-gray-900 rounded-xl p-4 md:p-8 shadow-lg">
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                  <XAxis dataKey="name" stroke="#888" />
                  <YAxis stroke="#888" />
                  <Tooltip
                    contentStyle={{ backgroundColor: '#333', border: 'none' }}
                    labelStyle={{ color: '#fff' }}
                  />
                  <Bar dataKey="value" fill="#4f46e5" radius={[4, 4, 0, 0]} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="container mx-auto px-4 py-16">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold mb-4">Benefits of Our BI Solutions</h2>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            Experience the tangible impact of data-driven decision making on your business performance.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div className="flex">
            <div className="mr-6">
              <div className="p-3 bg-blue-600 rounded-full">
                <Zap size={24} />
              </div>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-2">Faster Decision Making</h3>
              <p className="text-gray-300">
                Reduce analysis time by up to 80% with intuitive dashboards and automated insights, allowing you to act quickly on emerging opportunities and challenges.
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="mr-6">
              <div className="p-3 bg-blue-600 rounded-full">
                <TrendingUp size={24} />
              </div>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-2">Increased Revenue</h3>
              <p className="text-gray-300">
                Our clients typically see a 15-25% increase in sales through better customer targeting, product optimization, and operational improvements.
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="mr-6">
              <div className="p-3 bg-blue-600 rounded-full">
                <Database size={24} />
              </div>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-2">Data Democratization</h3>
              <p className="text-gray-300">
                Empower all team members with self-service analytics, eliminating bottlenecks and fostering a data-driven culture throughout your organization.
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="mr-6">
              <div className="p-3 bg-blue-600 rounded-full">
                <PieChart size={24} />
              </div>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-2">Competitive Advantage</h3>
              <p className="text-gray-300">
                Stay ahead with AI-powered predictive insights that anticipate market changes and customer needs before your competitors can react.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-blue-600 to-purple-600 py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to Unlock the Power of Your Data?</h2>
          <p className="text-xl mb-8 max-w-3xl mx-auto">
            Our team of BI experts is ready to build a custom solution that addresses your specific business challenges.
          </p>
          <button
            onClick={() => openModal('consultation')}
            className="bg-white text-gray-900 hover:bg-gray-100 px-8 py-3 rounded-full font-semibold text-lg transition flex items-center mx-auto"
          >
            Schedule a Free Consultation
            <ArrowRight className="ml-2" size={20} />
          </button>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="container mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold mb-12 text-center">Frequently Asked Questions</h2>
        <div className="max-w-3xl mx-auto space-y-6">
          <div className="bg-gray-800 rounded-lg p-6">
            <h3 className="text-xl font-bold mb-2">Do I need technical expertise to use your BI solutions?</h3>
            <p className="text-gray-300">
              No. Our solutions are designed with user-friendly interfaces that require no technical expertise. We also provide training and ongoing support to ensure your team can easily navigate and extract maximum value from your dashboards.
            </p>
          </div>
          <div className="bg-gray-800 rounded-lg p-6">
            <h3 className="text-xl font-bold mb-2">How long does it take to implement a BI solution?</h3>
            <p className="text-gray-300">
              Most of our BI implementations take 2-4 weeks from initial consultation to deployment. We follow an agile approach, delivering value incrementally so you can start seeing results quickly while we continue to refine and expand your solution.
            </p>
          </div>
          <div className="bg-gray-800 rounded-lg p-6">
            <h3 className="text-xl font-bold mb-2">How do you ensure data security and privacy?</h3>
            <p className="text-gray-300">
              Security is our top priority. We implement enterprise-grade encryption, role-based access controls, and regular security audits. All solutions are built to comply with relevant data protection regulations, including GDPR and CCPA.
            </p>
          </div>
          <div className="bg-gray-800 rounded-lg p-6">
            <h3 className="text-xl font-bold mb-2">Can you integrate with our existing systems?</h3>
            <p className="text-gray-300">
              Yes. Our BI solutions can integrate with virtually any data source, including popular CRMs, ERPs, spreadsheets, databases, and cloud services. We handle all the technical aspects of integration to provide you with a seamless experience.
            </p>
          </div>
        </div>
      </div>

      {/* Contact Form Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-gray-800 rounded-lg w-full max-w-md relative">
            <button
              onClick={() => setShowModal(false)}
              className="absolute top-4 right-4 text-gray-400 hover:text-white"
              disabled={isSubmitting}
            >
              <CloseIcon size={24} />
            </button>

            <div className="p-6">
              <h3 className="text-2xl font-bold mb-6 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
                Get Started with Mosyni AI
                {formData.inquiryType === 'consultation' && " - Consultation Request"}
              </h3>

              {submitStatus.success === null ? (
                <form onSubmit={handleSubmit}>
                  <div className="space-y-4">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium mb-1">Full Name</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label htmlFor="email" className="block text-sm font-medium mb-1">Email Address</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label htmlFor="company" className="block text-sm font-medium mb-1">Company Name</label>
                      <input
                        type="text"
                        id="company"
                        name="company"
                        value={formData.company}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label htmlFor="phone" className="block text-sm font-medium mb-1">Phone Number</label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label htmlFor="message" className="block text-sm font-medium mb-1">How can we help you?</label>
                      <textarea
                        id="message"
                        name="message"
                        value={formData.inquiryType === 'consultation' ?
                          formData.message || "I'm interested in scheduling a consultation about Business Intelligence solutions." :
                          formData.message}
                        onChange={handleInputChange}
                        rows="3"
                        className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        disabled={isSubmitting}
                      ></textarea>
                    </div>

                    <div className="pt-2">
                      <button
                        type="submit"
                        className="w-full bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-white font-bold py-3 px-4 rounded-md transition-all duration-200"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? 'Sending...' : 'Submit'}
                      </button>
                    </div>
                  </div>
                </form>
              ) : (
                <div className={`p-4 rounded-md mb-4 ${submitStatus.success ? 'bg-green-700 bg-opacity-30' : 'bg-red-700 bg-opacity-30'}`}>
                  <p className="text-center">
                    {submitStatus.message}
                  </p>
                </div>
              )}

              <p className="text-sm text-gray-400 mt-4 text-center">
                We'll get back to you within 24 hours.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BusinessIntelligencePage;